
:root { 
  --black: #3a3a3a;
  --white: #f9f9f9;
  --white-bkgd: #fff;
  --gray-bkgd: #eee;
  --nav-back-color-style: #1B6CB7;
  --nav-back-color-submenu: #094882;
  --nav-link-color-style: var(--white);
  --graphic-blue: #176bd3;
  --graphic-blue-light: #00C6FF;
  --page-bkgd: #DCDCDC;
  --main-title: #042A68; 
  --sub-title: #009ECC;
  --link: #077978;
  --tools-green: #45F2B9;
  --toolbox-title: #00C87F;
  --toolbox-paragraph: #03934D;
  --toolbox-link: #004F98;
  --toolbox-link-select: #003668;
  --footer-bkgd: #283247;
  --checkbox-green: #14CD8D;
  --checkbox-border: #a7a7a7;
  --checkbox-bkgd: #F5F5F5;
  --table-th: #027EA1;
  --table-striped: #EFF9FC;
}

