  
@font-face {
  font-family: 'Raleway-Medium';
  font-style: normal;
  font-weight: normal;
  font-stretch: normal; 
  src: url(../fonts/Raleway/Raleway-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway-Light';
  font-style: normal;
  font-weight: lighter;
  font-stretch: normal; 
  src: url(../fonts/Raleway/Raleway-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway-Bold';
  font-style: bold;
  font-weight: bold;
  font-stretch: bold; 
  src: url(../fonts/Raleway/Raleway-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway-ExtraBold';
  font-style: bold;
  font-weight: bold;
  font-stretch: bold; 
  src: url(../fonts/Raleway/Raleway-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-Regular';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Lato/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-Bold';
  font-style: bold;
  font-weight: bold;
  src: url(../fonts/Lato/Lato-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter', sans-serif;
  src: url(../fonts/Inter/Inter-VariableFont_slnt\,wght.ttf) format('truetype');
}
