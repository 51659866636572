:root {
  --data-dictionary__sidebar-width: 320px;
  --data-dictionary__switch-border-radius: 4px;
  --data-dictionary__switch-border-color: var(--g3-color__black);
}

.data-dictionary {
  display: flex;
  height: calc(100vh);
}

.data-dictionary__sidebar {
  width: var(--data-dictionary__sidebar-width);
  min-width: var(--data-dictionary__sidebar-width);
  height: 100%;
  background-color: var(--g3-color__white);
  overflow-y: scroll;
  border-right: 1px solid var(--g3-color__smoke);
}

.data-dictionary__main {
  width: calc(100vw - var(--data-dictionary__sidebar-width));
  min-width: 900px;
  /* verflow-y: scroll; */
}

.data-dictionary__graph {
  overflow: hidden;
  height: 100%;
  position: relative;
}

.graph-loading-info{
  display: flex;
  padding-top: 150px;
  padding-left: calc(50% - 40px);
}

.data-dictionary__table {
  padding: 20px;
}

.data-dictionary__graph--hidden,
.data-dictionary__table--hidden {
  display: none;
}

.data-dictionary__switch {
  display: flex;
  border: 1px solid var(--data-dictionary__switch-border-color);
  border-radius: var(--data-dictionary__switch-border-radius);
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: .02rem;
  margin: 15px;
}

.data-dictionary__switch-button {
  width: 50%;
  text-align: center;
  padding: 10px;
  border-left: 1px solid var(--data-dictionary__switch-border-color);
}

.data-dictionary__switch-button:hover {
  background-color: var(--g3-color__base-blue-light);
  color: var(--g3-color__white);
}

.data-dictionary__switch-button:first-child {
  border-left: none;
  border-top-left-radius: var(--data-dictionary__switch-border-radius);
  border-bottom-left-radius: var(--data-dictionary__switch-border-radius);
}

.data-dictionary__switch-button:last-child {
  border-top-right-radius: var(--data-dictionary__switch-border-radius);
  border-bottom-right-radius: var(--data-dictionary__switch-border-radius);
}

.data-dictionary__switch-button--active {
  background-color: var(--g3-color__base-blue);
  color: var(--g3-color__white);
}

.lds-title{
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 1.1875rem;
  font-family: 'Inter',sans-serif;
  font-weight: 500;
  color: #1588ae;
}


.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #1588ae;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/* DataDictionaryTable */
:root {
  --dictionary-header-color: var(--g3-color__gray);
  --dictionary-border-color: var(--g3-color__silver);
  --dictionary-border-color--hover: var(--g3-color__lightgray);
}

/* DataDictionaryNode */
.data-dictionary-node {
  background: var(--g3-color__white);
  border-bottom: 1px solid var(--dictionary-border-color);
  border-right: 1px solid var(--dictionary-border-color);
  display: flex;
  justify-content: space-between;
  padding: 10px 5px 10px 10px;
  border-left-width: 5px;
  border-left-style: solid;
  border-left-color: var(--dictionary-border-color);
  cursor: pointer;
}

.data-dictionary-node__title {
  width: 260px;
  flex-grow: 0;
  flex-shrink: 0;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.data-dictionary-node:hover .data-dictionary-node__title {
  color: var(--g3-color__base-blue);
}

.data-dictionary-node__file-icon {
  margin-right: 10px;
  position: relative;
  top: 3px;
}

.data-dictionary-node__toggle-icon {
  margin-left: 10px;
  position: relative;
  top: 3px;
}

.data-dictionary-node__description {
  flex-grow: 1;
  padding: 0 10px;
}

.data-dictionary-node__download-group {
  display: inline;
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: 5px;
  border-left: 1px solid var(--dictionary-border-color);
}

.data-dictionary-node__button-wrap {
  border-right: 1px solid var(--dictionary-border-color);
}

.data-dictionary-node__button-wrap:last-child {
  border-right: none;
}

.data-dictionary-node__download-button {
  width: 70px;
  min-width: unset; /* override .g3-button's 152px min-width */
  height: 30px;
  margin: 0 6px;
  padding: unset; /* override .g3-button's paddings */
  font-weight: normal;
  display: inline;
}

.data-dictionary-node__download-button:hover,
.data-dictionary-node__download-button:active {
  color: white;
}

.data-dictionary-node__download-button .g3-icon {
  position: relative;
  top: 1px;
  left: -2px;
}

.data-dictionary-node__property {
  margin: 20px;
  position: relative;
}

.data-dictionary-node__property-summary {
  margin: 5px 0;
}

.data-dictionary-node__property-close {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.data-dictionary-node__property-close:hover {
  color: var(--g3-color__black);
}

.data-dictionary-node__property-close-icon {
  position: relative;
  top: 4px;
  margin-left: 10px;
  background-color: var(--g3-color__gray);
}

.data-dictionary-node__property-close:hover .data-dictionary-node__property-close-icon {
  background-color: var(--g3-color__black);
}

