.action-layer__empty {
	position: absolute;
	top: 0;
	left: 0;
	text-align: center;
	margin: auto 0;
	width: 100%;
	padding-top: 300px;
	font-size: 1.3em;
	font-weight:bold;
}

.action-layer {
	position: absolute;
	top: 0;
	left: 0;
}

.action-layer__clear-search {
  margin-left: 60px;
  margin-top: 15px;
}