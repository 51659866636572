.data-dictionary-property-table__table {
  background-color: var(--g3-color__white);
  border-collapse: collapse;
  width: 100%;
}

.data-dictionary-property-table__data {
  padding: 10px;
  border: 1px solid var(--dictionary-border-color);
  text-align: left;
  vertical-align: top;
}

.data-dictionary-property-table--without-border tr:first-child th.data-dictionary-property-table__data {
  border-top: 0;
}
.data-dictionary-property-table--without-border tr:last-child td.data-dictionary-property-table__data {
  border-bottom: 0;
}
.data-dictionary-property-table--without-border tr td.data-dictionary-property-table__data:first-child,
.data-dictionary-property-table--without-border tr th.data-dictionary-property-table__data:first-child {
  border-left: 0;
}
.data-dictionary-property-table--without-border tr td.data-dictionary-property-table__data:last-child,
.data-dictionary-property-table--without-border tr th.data-dictionary-property-table__data:last-child {
  border-right: 0;
}

.data-dictionary-property-table__head {
  background-color: var(--dictionary-header-color);
  color: var(--g3-color__white);
}

.head-top-line{
  border-top: 1px solid white;
}

.type-col-width{
  width: 100px;
  word-break: break-word;
}

.required-col-width{
  width:120px;
}

.sticky-table__head{
    position: sticky;
    top: 40px;
    background-color: var(--dictionary-header-color);
    color: var(--g3-color__white);
    z-index: 1;
}

.data-dictionary-property-table__row {
  cursor: pointer;
}

.data-dictionary-property-table__row:hover {
  background: #dcdcdc;
}

.values-row{
  background-color:#a5a5a5;
}

.data-dictionary-property-table__required {
  color: var(--g3-color__highlight-orange);
}

.data-dictionary-property-table__required-icon {
  background-color: var(--g3-color__highlight-orange);
}

.data-dictionary-property-table__span {
  display: inline;
}

.data-dictionary-property-table__span--highlight {
  color: var(--g3-color__highlight-orange);
  font-weight: 600;
}

.data-dictionary-property-table__span--new-line {
  display: block;
}

.data-dictionary-property-table__button {
  width: 80px;
  min-width: unset;
  height: 30px;
  padding: unset;
  font-weight: normal;
  border: none;
}

.data-dictionary-values-table__toggle-button {
  width: 150px;
  min-width: unset;
  height: 30px;
  padding: unset;
  font-weight: normal;
  font-size: 14px;
  border: none;
  background-color: #1B6BEE;
}

tr.matched-row{
  background-color:white;
}
tr.hidden-row{
  display: none;
}
tr.display-row{
  display: table-row;
}

.values-column{
  width:20%;
}
.icdo-column{
  width:10%;
}
.ncit-column{
  width:10%;
}
.syns-column{
  width:60%;
}