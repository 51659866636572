tr.matched-row{
  background-color:white;
}
tr.hidden-row{
  display: none;
}
tr.display-row{
  display: table-row;
}

.values-column{
  width:20%;
}
.icdo-column{
  width:10%;
}

.ncit-column{
  width:10%;
}
.syns-column{
  width:60%;
}

.sub-table{
  padding: 0px;
  border-bottom: 1px solid var(--dictionary-border-color);
  text-align: left;
  vertical-align: top;
}

.ncits-table{
  border: 0px;
  width: 100%;
}

.ncits-table tr{
  border-bottom: 1px solid var(--dictionary-border-color);
}

.ncits-table tbody > tr:last-child {
  border-bottom: 0px;
}

.ncits-table td{
  padding: 10px;
}

.sub-table-ncit-col{
  width: 14.2%;
  /*border-right: 1px solid var(--dictionary-border-color);*/
  vertical-align: top;
}

.pt-row {
	width:100%;
	display: flex;
}

.pt-data {
	width: 80%;
}

.pt-button {
	width: 20%;
    text-align: right;
}

.pt-toggle-icon{
  color: #1B6BEE;
}

.pt-toggle-icon:focus{
  outline: none;
}

.syns-area{
	width: 100%;
	padding: 12px;
}

.syns-table{
	border: 1px solid #dee2e6;
	width: 100%;
    margin-bottom: 1rem;
    color: #212529;
}