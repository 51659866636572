.graph-node--faded {
  opacity: var(--opacity--faded);
  cursor: none;
  pointer-events: none;
}

.graph-node--half-faded {
  opacity: var(--opacity--half-faded);
}

.graph-node--clickable {
  cursor: pointer;
}

.graph-node--not-clickable {
  cursor: default;
}

.graph-node__rect {
  fill: var(--g3-color__white);
  stroke-width: 2px;
}

.graph-node--dashed .graph-node__rect {
  stroke-dasharray: 5, 5;
}

.graph-node--half-faded .graph-node__rect {
  stroke-dasharray: 5, 5;
}

.graph-node:hover .graph-node__rect {
  fill: var(--g3-color__highlight-orange);
}

.graph-node__text,
.graph-node__tspan {
  font-weight: 600;
  fill: var(--g3-color__black);
  pointer-events: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.graph-node__text--highlight,
.graph-node__tspan--highlight {
  fill: var(--g3-color__highlight-orange);
}

.graph-node:hover .graph-node__text,
.graph-node:hover .graph-node__tspan {
  fill: var(--g3-color__white);
}

.graph-node:hover .graph-node__text--highlight,
.graph-node:hover .graph-node__tspan--highlight {
  fill: var(--g3-color__black);
}