.canvas__zoom-button-group {
  position: absolute;
  top: 100px;
  left: 0px;
  border-radius: 15px 0px 0px 15px;
  background: #78b9ce;
  width: 50px;
  z-index: 1;
}

.canvas__zoom-button {
  background-color: #6196aa;
  width: 40px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 20px;
  margin-left: 5px;
}

.canvas-button-icon{
  margin-top: 10px;
  color:white;
  font-size:20px;
}

.canvas__zoom-button:hover,
.canvas__zoom-button:active {
  background-color: var(--g3-color__base-blue-light);
}

.canvas__zoom-icon {
  background-color: var(--g3-color__white);
  width: 20px;
  height: 20px;
  margin-top: 5px;
}

.canvas__zoom-icon--zoom-in {
  position: relative;
  top: 9px;
}